$(function () {
    var page = $( ".areas-js" )[0];
    if(page){
      window.addEventListener('scroll', function(){
        // 1 screen
        Paralax(".fs-paralax-wrap-js", ".fs-paralax-item1-js", 0, 25, true, 1);
        Paralax(".fs-paralax-wrap-js", ".fs-paralax-item2-js", 0, 25, true, 1);
        // 2 screen
        Paralax(".ts-1main-wrap-pjs", ".ts-1main-pjs", 0, 25, true, 1);
        Paralax(".ts-1secondary-wrap-pjs", ".ts-1secondary-pjs", 0, 25, true, 1);
      })
    }
  });