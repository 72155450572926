const validationReg = {
  email: /^[\w\-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  phone:
    /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]‌​)\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)([2-9]1[02-9]‌​|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})\s*(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+)\s*)?$/,
};

const debounce = (func, timeout = 100) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

const Validation = (data) => {
  return new Proxy(data, {
    get(target, prop) {
      if (prop === "isInvalid") {
        const invalidKeys = Object.keys(target).reduce(
          (acc, cur) =>
            target[cur].valid
              ? acc
              : Object.assign(acc, { [cur]: target[cur].error }),
          {}
        );
        return invalidKeys;
      }

      return Reflect.get(...arguments);
    },
    set(target, prop, value) {
      let valid = false;
      let error = null;

      if (target[prop].required) {
        valid = !!value;

        if (!valid) error = "required";
      }

      if (valid && target[prop].reg) {
        valid = validationReg[target[prop].reg].test(value);

        if (!valid) error = "reg";
      }

      target[prop].valid = valid;
      target[prop].error = error;

      return true;
    },
  });
};

const eventListener = (name, form, fields, fieldsValidation) => {
  const element = form.find(`[name="${name}"]`)[0];

  if (!element) return;

  const field = fields[name];

  const func = debounce((e) => {
    fieldsValidation[name] = e.target.value;

    if (field.required || field.reg) {
      $(element).removeClass("error");
      $(element).parents(".form__field-wrapper").find(".form__error").text("");
    }
  });

  $(element).on(field.event, (e) => func(e));
};

const checkValidation = (invalidList, isInvalid, errors) => {
  isInvalid.forEach((field) => {
    const input = $(`[name="${field}"]`);

    $(input).addClass("error");
    $(input)
      .parents(".form__field-wrapper")
      .find(".form__error")
      .text(errors[field][invalidList[field]]);
  });
};

const fetchFormData = (form, buttons) => {
  const formData = $(form)
    .serializeArray()
    .reduce((acc, cur) => {
      if (/^\w+\[/.test(cur.name)) {
        const matches = cur.name.match(/^\w+\[/);
        if (matches) {
          const key = matches[0].slice(0, -1);
          const value = cur.name.match(/\[\w+\]/)[0].slice(1, -1);
          return Object.assign(acc, {
            [key]: acc[key] ? `${acc[key]}, ${value}` : value,
          });
        } else return acc;
      }
      return Object.assign(acc, { [cur.name]: cur.value });
    }, {});

  buttons.forEach((element) => $(element).attr("disabled", true));

  $.ajax({
    url: "/api/mail.php",
    type: "POST",
    data: JSON.stringify(formData),
    contentType: "application/json; charset=utf-8",
    dataType: "json",
    success: function () {
      window.location.href = "/thank.html";
    },
    error: function () {
      alert("Something went wrong");
      buttons.forEach((element) => $(element).attr("disabled", false));
    },
  });
};

$(function () {
  if (!$(".form__dropdown").length) return;

  $(window).on("click", function () {
    $(".form__dropdown-list").attr("hidden", true);
    $(".form__dropdown").removeClass("active");
  });

  $(".form__dropdown").each(function () {
    const input = $(this).find("input")[0];
    const list = $(this).find(".form__dropdown-list")[0];
    const listItems = $(this).find(".form__dropdown-list li");
    const placeholder = $(this).find(".form__dropdown-wrapper span")[0];

    const toggle = () => {
      const isActive = $(this).hasClass("active");

      $(this).toggleClass("active");
      $(list).attr("hidden", isActive);
    };

    $(input).on("click", toggle);

    $(this).on("click", function (e) {
      e.stopPropagation();
    });

    $(listItems).on("click", function (e) {
      $(input).val($(e.target).attr("value"));
      $(this).parents().find("li").removeClass("active");
      $(this).addClass("active");
      $(input).trigger("input");
      $(placeholder).attr("hidden", true);
      toggle();
    });
  });
});
