$(function () {
    var header = $( ".header-js" );
    if(header[0]){
        window.addEventListener('scroll', function(){
            if(window.scrollY > 125 && !$( ".header-js" ).hasClass('fixed-header')){
                header.addClass('fixed-header');
            }else if(window.scrollY === 0 && $( ".header-js" ).hasClass('fixed-header')){
                header.removeClass('fixed-header');
            }
        })
    }
});