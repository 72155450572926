$(function () {
  const fieldsNext = {
    name: {
      required: true,
      valid: false,
      event: "input",
      error: "required",
    },
    email: {
      required: true,
      reg: "email",
      valid: false,
      event: "input",
      error: "required",
    },
    phone: {
      required: true,
      valid: false,
      event: "input",
      error: "required",
    },
  };

  const fieldsSend = {
    investor_type: {
      required: true,
      valid: false,
      event: "input",
      error: "required",
    },
    your_capital: {
      required: true,
      valid: false,
      event: "input",
      error: "required",
    },
  };

  const errors = {
    name: {
      required: "Full Name is required",
    },
    email: {
      required: "Email is required",
      reg: "Email is invalid",
    },
    phone: {
      required: "Phone is required",
      reg: "Phone is invalid",
    },
    investor_type: {
      required: "Investor Type is required",
    },
    your_capital: {
      required: "Your Capital is required",
    },
  };

  const form = $("#form-invest-with");

  if (!form.length) return;

  const steps1 = $("[data-step='1']")[0];
  const steps2 = $("[data-step='2']")[0];
  const progress = $("[data-progress]")[0];

  const nextValidation = Validation(fieldsNext);
  const sendValidation = Validation(fieldsSend);

  const nextButton = $('[data-button="next"]')[0];
  const backButton = $('[data-button="back"]')[0];
  const sendButton = $('[data-button="send"]')[0];

  Object.keys(fieldsNext).map((name) => {
    eventListener(name, form, fieldsNext, nextValidation);
  });

  Object.keys(fieldsSend).map((name) => {
    eventListener(name, form, fieldsSend, sendValidation);
  });

  $(nextButton).on("click", () => {
    
    const invalidList = nextValidation.isInvalid;
    const isInvalid = Object.keys(invalidList);

    if (isInvalid.length) {
      checkValidation(invalidList, isInvalid, errors);
      return;
    }

    $(steps1).attr("hidden", true);
    $(steps2).attr("hidden", false);
    $(progress).attr("data-progress", "2");
    $('[data-progresstext="2"]').addClass("active");
  });

  $(backButton).on("click", () => {
    $(steps1).attr("hidden", false);
    $(steps2).attr("hidden", true);
    $(progress).attr("data-progress", "1");
    $('[data-progresstext="2"]').removeClass("active");
  });

  form.on("submit", function (e) {
    e.preventDefault();
    const invalidList = sendValidation.isInvalid;
    const isInvalid = Object.keys(invalidList);

    if (isInvalid.length) {
      checkValidation(invalidList, isInvalid, errors);
      return;
    }

    fetchFormData(this, [sendButton, backButton]);
  });
});
