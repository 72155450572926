// =require ./paralax.js
$(function () {
    $( ".burger-menu-opener" ).click(function() {
      $('.mobile-menu-js').addClass('open');
    });
    $(".mobile-menu-closer").click(function() {
      $('.mobile-menu-js').removeClass('open');
  });

  
  const arr = [ "portfolio", "about", "contact", "cross-chain", "defi", "metaverse", "gaming", "nfts"]
  arr.forEach(el => {
    var link = $('#' + el + '-link')
    if(link[0] && location.pathname.includes(el)){
      link.addClass('active')
    }
    
    var link_mb = $('#' + el + '-link-mb')
    if(link_mb[0] && location.pathname.includes(el)){
      link_mb.addClass('active')
    }
  })
});
  