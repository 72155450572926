//=require ./custom/AttrPlugin.js
//=require ./custom/BezierPlugin.js
//=require ./custom/CSSPlugin.js
//=require ./custom/EasePack.js
//=require ./custom/TweenLite.js
//=require ./custom/TimelineLite.js

function Paralax(parentSelector, elementSelector, transition, animationSpeed, offset, adaptive){
    var parent = document.querySelector(parentSelector)
    var element = document.querySelector(elementSelector)
    var adaptiveMod;
    if(!adaptive) {
        adaptiveMod = 992;
    }
    if(adaptive) {
        adaptiveMod = adaptive;
    }
    if(parent !== null && window.innerWidth > adaptiveMod){
        var sizeCalc = parseInt(getComputedStyle(document.body).fontSize) / 20;
        var elemOffset = parent.clientHeight / (animationSpeed * sizeCalc);
        if(window.pageYOffset + parent.clientHeight > parent.offsetTop){
            var scrollSize = window.pageYOffset - parent.offsetTop;
            if(offset){
                element.setAttribute("style", "top:" + elemOffset + ";");
            }
            var currentScrolSize = -scrollSize / (animationSpeed * sizeCalc)
            TweenMax.to(element, transition, {y: currentScrolSize});
        }
    }
    if(!(window.innerWidth > adaptiveMod)){
        element.setAttribute("style", "top:" + "" + "transform:" + "");
    }
}
