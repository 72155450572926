$(function () {
    var page  = $( ".portfolio" );
    if(page[0]){
        var portfolioListFilter = Object.entries($( ".pf-item-js" )).filter((el) => {
            return !Number.isNaN(Number(el[0]))
        })
        var portfolioList = portfolioListFilter.map(el => el[1])
        function openDetails(){
            if(this.classList.contains('mobile-active')){
                this.classList.remove('mobile-active')
            }else{
                portfolioList.forEach(el => {
                    if(el.classList.contains('mobile-active')){
                        el.classList.remove('mobile-active')
                    }
                })
                this.classList.add('mobile-active')
            }
            
         }
        portfolioList.forEach(el => {
            el.addEventListener("click", openDetails)
        })
    
        // filters

        var activeFilter = 'all'
        var filterListFilter = Object.entries($( ".filter-btn-wrap-js" )).filter((el) => {
            console.log(el)
            return !Number.isNaN(Number(el[0]))
        })
        var filterList = filterListFilter.map(el => el[1])
        function changeFilter(){
            if(!this.classList.contains('active')){
                filterList.forEach(el => {
                    if(el.classList.contains('active')){
                        el.classList.remove('active')
                    }
                })
                this.classList.add('active')
                portfolioList.forEach(el => {
                    if(el.classList.contains('denided')){
                        el.classList.remove('denided')
                    }
                    if(!el.dataset.filter_list.includes(this.dataset.filter_name) && this.dataset.filter_name !== 'all'){
                        el.classList.add('denided')
                    }
                })
            }
         }
         filterList.forEach(el => {
            el.addEventListener("click", changeFilter)
        })
        
    }
});