// =require ./paralax.js
$(function () {
  var home = $( ".home-js" )[0];
  if(home){
    window.addEventListener('scroll', function(){
      // 1 screen
      Paralax(".fs-paralax-wrap-js", ".fs-paralax-item1-js", 0, 10, true, 1);
      Paralax(".fs-paralax-wrap-js", ".fs-paralax-item2-js", 0, 10, true, 1);
      //cube
      Paralax(".fs-paralax-2-wrap-1", ".fs-paralax-2-item-js", 0, 15, true, 1);
     
      // 3 screen
      Paralax(".ts-1main-wrap-pjs", ".ts-1main-pjs", 0, 25, true, 1);
      Paralax(".ts-1secondary-wrap-pjs", ".ts-1secondary-pjs", 0, 25, true, 1);
      Paralax(".ts-2main-wrap-pjs", ".ts-2main-pjs", 0, 25, true, 1);
      Paralax(".ts-2secondary-wrap-pjs", ".ts-2secondary-pjs", 0, 25, true, 1);
      // 4 screen
      Paralax(".fos-wrap-pjs", ".fos-item-left-pjs", 0, 25, true, 1);
      Paralax(".fos-wrap-pjs", ".fos-item-right-pjs", 0, 25, true, 1);
    })
  }
  window.addEventListener('scroll', function(){
    if(home && window.scrollY !== 0 && !$( ".home-js" ).hasClass('scrolled')){
      $( ".home-js" ).addClass('scrolled')
      $('html, body').animate({
        scrollTop: $("#second-screen-js").offset().top
        }, {
            duration: 170,   
            easing: "linear" 
        });
    }else if(
      home && window.scrollY === 0 && $( ".home-js" ).hasClass('scrolled')
    ){
      $( ".home-js" ).removeClass('scrolled')
    }
  })
});