// =require ./paralax.js
$(function () {
  if ($(".about-main__block").length) {
    $(".about-main__block .button__learn-more").on("click", () => {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#anchor-learn-more").offset().top,
        },
        1000
      );
    });
  }
});
