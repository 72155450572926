$(function () {
  const fieldsSend = {
    name: {
      required: true,
      valid: false,
      event: "input",
      error: "required",
    },
    email: {
      required: true,
      reg: "email",
      valid: false,
      event: "input",
      error: "required",
    },
  };

  const errors = {
    name: {
      required: "Full Name is required",
    },
    email: {
      required: "Email is required",
      reg: "Email is invalid",
    },
  };

  const form = $("#form-contact");

  if (!form.length) return;

  const sendValidation = Validation(fieldsSend);
  const sendButton = $('[data-button="send"]')[0];

  Object.keys(fieldsSend).map((name) => {
    eventListener(name, form, fieldsSend, sendValidation);
  });

  form.on("submit", function (e) {
    e.preventDefault();
    const invalidList = sendValidation.isInvalid;
    const isInvalid = Object.keys(invalidList);

    if (isInvalid.length) {
      checkValidation(invalidList, isInvalid, errors);
      return;
    }

    fetchFormData(this, [sendButton]);
  });
});
